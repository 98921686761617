import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import { useSelector, useDispatch } from "react-redux";
import { registerChild } from "../redux/slices/userSlice"; // Import the registerChild action from userSlice
import { useTranslation } from 'react-i18next';

const UserRegistration = () => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentAddress, setCurrentAddress] = useState('');
  const [realName, setRealName] = useState('');
  const [username, setUsername] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [previewImage, setPreviewImage] = useState(''); // State to hold the preview image URL
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [realNameError, setRealNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');

  const dispatch = useDispatch(); // Initialize useDispatch hook
  const { registerChildRes, isLoading, error } = useSelector((state) => state.user); // Get user state

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files).slice(0, 5); // Limit to 5 files
    setSelectedFiles(files);
    // Assuming the first file is the profile picture and we want to preview it
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(files[0]);
    }
  };
  const handleRegisterChild = async () => {
    // Validation remains same but change variable names in the check
    if (!currentAddress) {
      setCurrentAddressError(t('Current address is required'));
    } else {
      setCurrentAddressError('');
    }
    if (!realName) {
      setRealNameError(t('Real name is required'));
    } else {
      setRealNameError('');
    }
    if (!username) { // This is actually email now
      setUsernameError(t('Username is required'));
    } else if (!/^[\w.@+-]+$/.test(username)) {
      setUsernameError(t('Username can only contain letters, numbers, and @/./+/-/_ characters'));
    } else {
      setUsernameError('');
    }
    if (!dateOfBirth) {
      setDateOfBirthError(t('Date of birth is required'));
    } else {
      setDateOfBirthError('');
    }
  
    if (currentAddress && realName && username && dateOfBirth && 
        /^[\w.@+-]+$/.test(username)) {
      const formData = new FormData();
      formData.append('address', currentAddress);
      formData.append('username', username);
      formData.append('first_name', realName);
      formData.append('date_of_birth', dateOfBirth);
  
      if (selectedFiles.length > 0) {
        formData.append('profile_picture', selectedFiles[0]);
      }
  
      dispatch(registerChild(formData));
    }
  };
  

  // Reset form on successful registration
  React.useEffect(() => {
    if (registerChildRes?.username) {
      setSelectedFiles([]);
      setCurrentAddress('');
      setRealName('');
      setUsername('');
      setDateOfBirth('');
      setPreviewImage(''); // Reset preview image on successful registration
      setCurrentAddressError('');
      setRealNameError('');
      setUsernameError('');
      setDateOfBirthError('');
    }
  }, [registerChildRes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'currentAddress':
        setCurrentAddress(value);
        setCurrentAddressError('');
        break;
      case 'realName':
        setRealName(value);
        setRealNameError('');
        break;
      case 'username':
        setUsername(value);
        if (!/^[\w.@+-]+$/.test(value)) {
          setUsernameError(t('Username can only contain letters, numbers, and @/./+/-/_ characters'));
        } else {
          setUsernameError('');
        }        
        break;
      case 'dateOfBirth':
        setDateOfBirth(value);
        setDateOfBirthError('');
        break;
      default:
        break;
    }
  };

  return (
    <>
    <Navigation/>
    <div className="form-container">
      <h2 className="form-title">{t('User Registration')}</h2>

      <div className="form-group">
        <label className="form-label">{t('current address')}</label>
        <input type="text" className="form-input" placeholder={t('Enter your address')} value={currentAddress} name="currentAddress" onChange={handleInputChange} />
        <small className="form-hint">{t('administrative divisions of Japan: Tokyo-to, Hokkai-do, Osaka-fu and Kyoto-fu')}</small>
        {currentAddressError && <p style={{ color: 'red', fontSize: 'small' }}>{currentAddressError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">{t('real name')}</label>
        <input type="text" className="form-input" placeholder={t('Enter real name')} value={realName} name="realName" onChange={handleInputChange} />
        <small className="form-hint">{t('maiden name')}</small>
        {realNameError && <p style={{ color: 'red', fontSize: 'small' }}>{realNameError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">{t('username')}</label>
        <input type="text" className="form-input" placeholder={t('Enter username')} value={username} name="username" onChange={handleInputChange} />
        {usernameError && <p style={{ color: 'red', fontSize: 'small' }}>{usernameError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">{t('Date of birth')}</label>
        <div className="dob-inputs">
          <input type="date" className="dob-select" value={dateOfBirth} name="dateOfBirth" onChange={handleInputChange} />
        </div>
        {dateOfBirthError && <p style={{ color: 'red', fontSize: 'small'}}>{dateOfBirthError}</p>}
      </div>

      <div className="form-group icon-setting">
        <label className="form-label">{t('Icon Setting')}</label>
        <div className="upload-box" onClick={() => document.getElementById("fileInput").click()}>
          <span className="upload-text">{t('Upload Image')}<br />({t('up to a maximum of 5 pieces')})</span>
          {previewImage && <img src={previewImage} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
        </div>
        <input
          id="fileInput"
          type="file"
          multiple
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
      </div>

      <button className="submit-button" onClick={handleRegisterChild} disabled={isLoading}>
        {isLoading ? t('Registering...') : t('Register')}
      </button>
      {/* {registerChildRes?.email && setTimeout(() => <p style={{ color: 'green' }}>{t('Registration successful!')}</p>, 200)}
      {error && setTimeout(() => <p style={{ color: 'red' }}>{error}</p>, 200)} */}
    </div>
    </>
  );
};

export default UserRegistration;
