import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingPlans, createCheckoutSession, getSubscriptionStatus, cancelSubscription } from '../redux/slices/billingSlice';
import Navigation from './Navigation';
import Loader from '../components/Loader';

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const { 
    plans, 
    subscriptionStatus, 
    isLoading, 
    error 
  } = useSelector((state) => state.billing);

  useEffect(() => {
    dispatch(getBillingPlans());
    dispatch(getSubscriptionStatus());
  }, [dispatch]);

  const handleUpgrade = async (planId) => {
    try {
      const response = await dispatch(createCheckoutSession(planId)).unwrap();
      if (response?.url) {
        window.location.href = response.url;
      }
    } catch (err) {
      console.error('Checkout error:', err);
    }
  };

  const handleCancel = async () => {
    try {
      await dispatch(cancelSubscription()).unwrap();
      // Refresh subscription status after cancellation
      dispatch(getSubscriptionStatus());
    } catch (err) {
      console.error('Cancel subscription error:', err);
    }
  };

  if (isLoading) return <Loader />;

  const isSubscribed = subscriptionStatus?.active;

  return (
    <>
      <Navigation />
      <div className="subscription-container">
        <h1 className="subscription-title">Rate Plans</h1>
        <p className="subscription-description">
          Basic functions are available free of charge.Depending on the functionality you need,<br />
          You can upgrade at any time.
        </p>

        <div className="subscription-box">
          {/* Free Plan */}
          <div className="subscription-half">
            <p className="subscription-type">Try basic functions for free</p>
            <h2 className="subscription-price">¥0</h2>
            <button 
              className="subscription-button"
              disabled={!isSubscribed}
            >
              {!isSubscribed ? 'Current Plan' : 'Free Plan'}
            </button>
            <ul className="feature-list">
              <li>What you can do1 What you can do1 What you can do1 What you can do1</li>
              <li>What you can do 2 what you can do 2 what you can do</li>
              <li>What you can do3 What you can do3 What you can do3</li>
              <li>What you can do4 What you can do4 What you can do4 What you can do4</li>
              <li>What you can do5 What you can do5 What you can do5</li>
            </ul>
          </div>
          
          {/* Premium Plan */}
          <div className="subscription-half">
            <p className="subscription-type">If you want to use all the features</p>
            <h2 className="subscription-price">
              ${plans?.[1]?.amount}<span>/{plans?.[1]?.interval}</span>
            </h2>
            {isSubscribed ? (
              <button 
                className="subscription-button cancel"
                onClick={handleCancel}
              >
                Cancel Subscription
              </button>
            ) : (
              <button 
                className="subscription-button upgrade"
                onClick={() => handleUpgrade(plans?.[1]?.id)}
              >
                upgrade
              </button>
            )}
            <ul className="feature-list">
              <li>What you can do1 What you can do1 What you can do1 What you can do1</li>
              <li>What you can do 2 what you can do 2 what you can do</li>
              <li>What you can do3 What you can do3 What you can do3</li>
              <li>What you can do4 What you can do4 What you can do4</li>
              <li>What you can do5 What you can do5 What you can do5</li>
              <li>What you can do6 What you can do6 What you can do6</li>
              <li>What you can do7 What you can do7 What you can do7</li>
            </ul>
          </div>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {/* Comparison Table */}
        <div className="comparison-section">
          <h2 className="comparison-title">Plans and Features</h2>
          <table className="comparison-table">
            <thead>
              <tr>
                <th>Free Plan</th>
                <th>Paid Plans</th>
              </tr>
            </thead>
            <tbody>
              <tr className="price-row">
                <td>¥0</td>
                <td>1,490 yen</td>
              </tr>
              <tr>
                <td>—</td>
                <td><span className="circle"></span></td>
              </tr>
              <tr>
                <td>—</td>
                <td><span className="circle"></span></td>
              </tr>
              <tr>
                <td>—</td>
                <td><span className="circle"></span></td>
              </tr>
              <tr>
                <td>Up to 3 people</td>
                <td>limitless</td>
              </tr>
              <tr>
                <td>—</td>
                <td><span className="circle"></span></td>
              </tr>
              <tr>
                <td>Up to 1000 entries</td>
                <td>limitless</td>
              </tr>
              <tr>
                <td>—</td>
                <td><span className="circle"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPage;