// src/api/fetchHelper.js
import axios from 'axios';

// Helper function to get the Authorization header
const auth = () => {
  const childToken = localStorage.getItem('child_token');
  const token = localStorage.getItem('token');

  // Log the type of token being used

  return `Bearer ${childToken ? childToken : token}`;
};

// Helper function to build query string from an object
const buildQueryParams = (params) => {
  if (!params) return '';
  const query = new URLSearchParams(params).toString();
  return query ? `?${query}` : '';
};

// Main function to fetch data using Axios

export async function fetchData({
  apiUrl,
  method = 'GET',
  body,
  formData,
  contentType = 'application/json',
  queryParams = {},
}) {
  // Check for auth paths more explicitly
  const isAuthPath = ['/password-reset-confirm', '/signup', '/password-reset', '/login'].some(path => 
    apiUrl.includes(path) && 
    !apiUrl.includes('/profile/login/') && 
    !apiUrl.includes('/artist-classes/signup/')
  );

  const requestConfig = {
    url: `${apiUrl}${buildQueryParams(queryParams)}`,
    method,
    headers: {
      ...(formData ? {} : { 'Content-Type': contentType }),
      Accept: contentType,
      // Always include Authorization unless it's specifically an auth path
      ...(!isAuthPath ? { Authorization: auth() } : {}),
    },
    data: formData || (contentType === 'application/json' ? JSON.stringify(body) : body),
  };

  // Add CSRF for non-GET methods
  if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)) {
    requestConfig.withCredentials = true;
    requestConfig.headers['X-CSRFToken'] = document.cookie.split('; ').find(row => row.startsWith('csrftoken'))?.split('=')[1] || '';
  }

  try {
    // Debug log in productionb

    const response = await axios(requestConfig);
    return response.data;
  } catch (error) {
    // Enhanced error logging
    console.error('Request failed:', {
      url: requestConfig.url,
      method: requestConfig.method,
      headers: requestConfig.headers,
      error: error.response?.data || error.message
    });

    const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
    throw new Error(errorMessage);
  }
}
