// VideoPage.js
import React from 'react';
import Navigation from "./Navigation";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const VideoPage = () => {
  const location = useLocation();
  const videoUrl = location.state?.artistClassVideoUrl;
  const { t } = useTranslation();

  // Function to check if URL is a YouTube link
  const isYoutubeUrl = (url) => {
    return url?.includes('youtube.com') || url?.includes('youtu.be');
  };

  // Function to extract YouTube video ID
  const getYoutubeVideoId = (url) => {
    if (!url) return null;
    
    // Handle both youtube.com and youtu.be URLs
    if (url.includes('youtube.com')) {
      return url.split('v=')[1]?.split('&')[0];
    } else if (url.includes('youtu.be')) {
      return url.split('youtu.be/')[1]?.split('?')[0];
    }
    return null;
  };

  const videoId = getYoutubeVideoId(videoUrl);

  return (
    <>
      <Navigation/>
      <div className="video-page">
        <div className="video-page-container">
          <div className="video-title">
            {/* <span className="channel-name">ABC</span>
            <span className="channel-text">accessory chanel</span> */}
          </div>
          <div className="video-wrapper">
            {isYoutubeUrl(videoUrl) ? (
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title={t('YouTube video player')}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div className="meeting-link-container">
                <h3>{t('Meeting Link')}</h3>
                <a 
                  href={videoUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="meeting-link"
                >
                  {videoUrl}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPage;