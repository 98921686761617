import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { getArtistClassDetail, artistClassSignUp } from '../redux/slices/contestSlice';
import { useLocation } from 'react-router-dom';
import { getTags } from '../redux/slices/workSlice';
import { decodeToken } from '../utils/auth'; // Assuming you have a function to decode the token
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const ArtistSignupDetail = () => {
  const dispatch = useDispatch();
  const { artistDetail, isLoading, error, signupRes } = useSelector(state => state.contest);
  const { tags } = useSelector((state) => state.work)
  const location = useLocation();
  const { artistClassId } = location.state || {};
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const { t } = useTranslation(); // Use the translation function

  useEffect(() => {
    if (artistClassId) {
      dispatch(getArtistClassDetail(artistClassId));
    }
  }, [dispatch, artistClassId]);
  useEffect(() => {
    if(signupRes?.artist_class) navigate(`/link-detail`, { state: { is_free: artistDetail?.is_free, class_id: artistDetail?.id  } });
    
  }, [signupRes, navigate]);

  const handleSignup = async () => {
    try {
      const childToken = localStorage.getItem('child_token');
      const decodedToken = decodeToken(childToken);
      const memberId = decodedToken.user_id;

      const body = {
        member: memberId,
        artist_class: artistClassId
      };

      const response = await dispatch(artistClassSignUp(body)).unwrap();
      
      // If class is paid, redirect to payment page with payment intent
      console.log("response::::::::::::", signupRes)
      if (!artistDetail.is_free && response.payment_intent_client_secret) {
        navigate('/payment', { 
          state: { 
            clientSecret: response.payment_intent_client_secret,
            amount: artistDetail.cost,
            artistClassId: artistClassId
          }
        });
      } else {
        console.log("free class else part")
        // For free classes, navigate to success page directly
        navigate('/link-detail', { 
          state: { 
            is_free: artistDetail.is_free, 
            class_id: artistDetail.id 
          } 
        });
      }
      
      setShowConfirmPopup(false);
    } catch (error) {
      console.error("Artist class signup error:", error);
    }
  };

  // Function to filter tag names based on artistClass tags
  const getTagNames = (artistClassTags) => {
    return artistClassTags.map(tagId => tags.find(tag => tag.id === tagId)?.name || 'Unknown Tag');
  };

  return (
    <>
      <Navigation />
      <div className="artist-sign-up-detail-container">
        <h1 className="artist-sign-up-detail-title">{t('Artist Class')}</h1>
        
        <div className="artist-sign-up-detail-content">
          <div className="artist-sign-up-detail-left">
            <div className="artist-sign-up-detail-image-wrapper">
              <img 
                src={artistDetail?.thumbnail} 
                alt={t('Learn to Draw with watercolours!')} 
                className="artist-sign-up-detail-image"
              />
              <div className="artist-sign-up-detail-image-title">
                {t('Learn to Draw with watercolours!')}
              </div>
            </div>
          </div>

          <div className="artist-sign-up-detail-right">
            <div className="artist-sign-up-detail-header">
              {/* <div className="artist-sign-up-detail-user">
                <img 
                  src="/path-to-avatar.jpg" .
                  alt="user avatar"
                  className="artist-sign-up-detail-avatar"
                />
                <span className="artist-sign-up-detail-username">
                  user_name_dayo
                </span>
              </div> */}

              <h2 className="artist-sign-up-detail-video-title">
                {artistDetail?.name || t('Video title. The title of the video. The title of the video. The title of the video.')}
              </h2>
              {!artistDetail?.member_signup?.is_signed_up && (
                <button className="artist-sign-up-detail-button" onClick={() => setShowConfirmPopup(true)}>
                  {t('Sign up for this artist class')}
                </button>
              )}
            </div>

            <div className="artist-sign-up-detail-description">
              <p>
                {t('Description of the work')}
              </p>
            </div>

            <div className="artist-sign-up-detail-footer">
              <h3>{t('tag')}</h3>
              <div className="artist-sign-up-detail-tags">
                {getTagNames(artistDetail?.tags || []).map((tag, index) => (
                  <span key={index} className="artist-sign-up-detail-tag">{tag}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
        {showConfirmPopup && (
         <div className="confirm-popup-overlay">
           <div className="confirm-popup">
             <h3 className="confirm-popup-title">{t('Are you sure to sign up for this class?')}</h3>
             <div className="confirm-popup-buttons">
               <button 
                 className="confirm-popup-button confirm-popup-confirm"
                 onClick={handleSignup}
               >
                 {t('Sign up')}
               </button>
               <button 
                 className="confirm-popup-button confirm-popup-cancel"
                 onClick={() => setShowConfirmPopup(false)}
               >
                 {t('Cancel')}
               </button>
             </div>
           </div>
         </div>
       )}
      </div>
    </>
  );
};

export default ArtistSignupDetail;
