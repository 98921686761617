import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Navigation from './Navigation';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const ContactUs = () => {
  const { t } = useTranslation(); // Use the translation function
  const validationSchema = Yup.object({
    componentName: Yup.string()
      .required(t('Component name is required')),
    inquiryDetails: Yup.string()
      .required(t('Inquiry details are required'))
      .min(10, t('Please provide more details')),
  });

  const formik = useFormik({
    initialValues: {
      componentName: '',
      inquiryDetails: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      // Handle form submission
    },
  });

  return (
    <>
      <Navigation />
      <div className="contact-container">
        <h1 className="contact-title">{t('Contact Us')}</h1>
        
        <form onSubmit={formik.handleSubmit} className="contact-form">
          <div className="contact-form-group">
            <label className="contact-label">{t('Component name')}</label>
            <input
              type="text"
              placeholder={t('Please fill in the subject line')}
              {...formik.getFieldProps('componentName')}
              className={`contact-input ${
                formik.touched.componentName && formik.errors.componentName 
                ? 'contact-input-error' 
                : ''
              }`}
            />
            {formik.touched.componentName && formik.errors.componentName && (
              <div className="contact-error">{formik.errors.componentName}</div>
            )}
          </div>

          <div className="contact-form-group">
            <label className="contact-label">{t('Inquiry Details')}</label>
            <textarea
              placeholder={t('Please enter your inquiry here')}
              {...formik.getFieldProps('inquiryDetails')}
              className={`contact-textarea ${
                formik.touched.inquiryDetails && formik.errors.inquiryDetails 
                ? 'contact-input-error' 
                : ''
              }`}
            />
            {formik.touched.inquiryDetails && formik.errors.inquiryDetails && (
              <div className="contact-error">{formik.errors.inquiryDetails}</div>
            )}
          </div>

          <button type="submit" className="contact-submit">
            {t('Save')}
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactUs;
