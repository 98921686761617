import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDesktopModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderAuthLink = () => {
    if (location.pathname === '/login') {
      return (
        <li className="nav-item position-relative">
          <a className="nav-link" href="/register">{t('Member Registration')}</a>
        </li>
      );
    } else if (location.pathname === '/register') {
      return (
        <li className="nav-item position-relative">
          <a className="nav-link" href="/login">{t('Login')}</a>
        </li>
      );
    } else {
      return (
        <>
        <li className="nav-item position-absolute">
          <a className="nav-link" href="/login">{t('Login')}</a>
          </li>
        <li className="nav-item position-absolute">
          <a className="nav-link" href="/register">
            {t('Member Registration')}
          </a>
        </li>
        </>
      );
    }
  };

  const logout = () => {
    if (localStorage.getItem('child_token')) {
      localStorage.removeItem('child_token');
      localStorage.removeItem('child_refresh_token');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
    navigate('/login');
  };

  // Define menu items with translation keys instead of translated text
  const mainMenuItems = [
    { key: 'Search Works', path: '/public-gallery' },
    { key: 'About us', path: '#' },
    { key: 'Blog', path: '#' },
    { key: 'Contact Us', path: '/contact-us' },
    { key: 'All Contests', path: '/contest-list' }
  ];

  const userMenuItems = [
    { key: 'My Gallery', path: '/my-work' },
    { key: 'Family Gallery', path: '/family-gallery' },
    { key: 'My Collection', path: '/my-collection' },
    { key: 'My Contest', path: '/my-contests' },
    { key: 'Artist Class', path: '/artist-list' },
    { key: 'Profile', path: '/user-info' },
    { key: 'Payment Information', path: '/account-payment-info' },
    { key: 'Account Information', path: '/account-info' },
    { key: 'Manage Users', path: '/add-user' },
    { key: 'All Contests', path: '/contest-list' }
  ];

  const filteredUserMenuItems = localStorage.getItem('child_token') || localStorage.getItem('token')
    ? (localStorage.getItem('child_token') 
        ? (localStorage.getItem('token') 
            ? userMenuItems.filter(item => !['Account Information', 'Manage Users', 'Payment Information'].includes(item.key))
            : userMenuItems)
        : userMenuItems.filter(item => ['Account Information', 'Manage Users', 'Payment Information'].includes(item.key)))
    : [];

  return (
    <>
      <header className="sticky-top sticky-header">
        <div className="container-fluid px-md-5">
          <nav className="navbar">
            <a className="navbar-brand" href="#">
              <img src={require('../assets/images/logo.png')} className="img-fluid" alt="logo" />
            </a>

            {/* Desktop Menu */}
            <div className="desktop-menu">
              <ul className="navbar-nav">
                {mainMenuItems
                  .filter(item => !(item.key === "All Contests" && !localStorage.getItem('child_token')))
                  .map((item) => (
                    <li className="nav-item" key={item.key}>
                      <a className="nav-link active" href={item.path}>{t(item.key)}</a>
                    </li>
                  ))}
               {!localStorage.getItem('token') && renderAuthLink()}
               <li className="nav-item">
                  <LanguageSwitcher />
                </li>
                <li className="nav-item desktop-hamburger">
                  <a className="nav-link" href="#" onClick={toggleDesktopModal}>
                    <img src={require('../assets/images/hamburger.png')} className="img-fluid" alt="hamburger" />
                  </a>
                </li>
              </ul>
            </div>

            {/* Mobile Menu Toggle */}
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Mobile Menu */}
            <div className={`mobile-menu ${isMobileMenuOpen ? 'show' : ''}`}>
              <div className="mobile-menu-content">
                <button className="btn-close" onClick={toggleMobileMenu} aria-label="Close">×</button>
                <LanguageSwitcher/>
                <div className="profile-info-box">
                  <h3>{t('My Page')}</h3>
                  <img src={require('../assets/images/logo.svg').default} className="img-fluid rounded-circle main-img" alt="user" />
                  <h4 className="mb-3">user_name_dayo</h4>
                  <div>
                    <h4>{t('Shared users')}</h4>
                    <div className="shared-users-images">
                      <img src={require('../assets/images/user1.png')} className="img-fluid rounded-circle small-img" alt="user1" />
                      <img src={require('../assets/images/user2.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user2" />
                      <img src={require('../assets/images/user3.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user3" />
                    </div>
                  </div>
                </div>

                <div className="mobile-action-buttons">
                  <a href="/register-work" className="btn-border-green">{t('Register your work')}</a>
                  <a href="/public-gallery" className="btn-border-green">{t('Find other users works')}</a>
                </div>

                <ul className="mobile-nav-list">
                  {[...mainMenuItems, ...filteredUserMenuItems].map((item) => (
                    <li key={item.key}>
                      <a href={item.path}>{t(item.key)}</a>
                    </li>
                  ))}
                  {!localStorage.getItem('token') && renderAuthLink()}
                  {localStorage.getItem('token') && (
                    <li>
                      <a href="#" onClick={logout}>{t('Logout')}</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {/* Desktop Modal */}
      {isModalOpen && (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-right" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" onClick={toggleDesktopModal} aria-label="Close">×</button>
                <div className="profile-info-box">
                  <h3>{t('My Page')}</h3>
                  <img src={require('../assets/images/logo.svg').default} className="img-fluid rounded-circle main-img" alt="user" />
                  <h4 className="mb-3">user_name_dayo</h4>
                  <div>
                    <h4>{t('Shared users')}</h4>
                    <div>
                      <img src={require('../assets/images/user1.png')} className="img-fluid rounded-circle small-img" alt="user1" />
                      <img src={require('../assets/images/user2.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user2" />
                      <img src={require('../assets/images/user3.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user3" />
                    </div>
                  </div>
                </div>
                <hr />
                <a href="/register-work" className="btn-border-green mb-3">{t('Register your work')}</a>
                <a href="/public-gallery" className="btn-border-green mb-3">{t('Find other users works')}</a>
                <div>
                  <ul>
                    {filteredUserMenuItems.map((item) => (
                      <li key={item.key}>
                        <a href={item.path}>{t(item.key)}</a>
                        <div>
                          <img src={require('../assets/images/angle-right.png')} className="img-fluid" alt="angle-right" />
                        </div>
                      </li>
                    ))}
                    {localStorage.getItem('token') && (
                      <li>
                        <a href="#" onClick={logout}>{t('Logout')}</a>
                        <div>
                          <img src={require('../assets/images/angle-right.png')} className="img-fluid" alt="angle-right" />
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
