import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInfo, updateAccountInfo, getProfiles } from '../redux/slices/userSlice';
import Navigation from './Navigation';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const AccountInformation = () => {
  const { t } = useTranslation(); // Use the translation function
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => state.user.accountInfoRes);
  const sharedProfiles = useSelector((state) => state.user.profilesRes);

  useEffect(() => {
    dispatch(getAccountInfo());
    dispatch(getProfiles());
  }, [dispatch]);
  // Validation Schema
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('Name is required'))
      .min(2, t('Name must be at least 2 characters')),
    address: Yup.string()
      .required(t('Current address is required')),
    email: Yup.string()
      .email(t('Invalid email format'))
      .required(t('Email is required')),
    password: Yup.string()
      .min(8, t('Password must be at least 8 characters'))
      .required(t('Password is required')),
  });

  const formik = useFormik({
    enableReinitialize: true, // Enable reinitialization when initialValues change
    initialValues: {
      name: accountInfo?.email || 'sample@gmail.com',
      address: accountInfo?.address || 'Kyoto (metropolitan area)',
      email: accountInfo?.email || 'sample@gmail.com',
      password: '****************',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(updateAccountInfo(values));
    },
  });

  return (
    <>
      <Navigation />
      <div className="info-container">
        <h1 className="info-title">{t('Account Information')}</h1>
        
        <form onSubmit={formik.handleSubmit} className="info-form">
          <div className="info-form-group">
            <label className="info-label">{t('Name')}</label>
            <input
              type="text"
              {...formik.getFieldProps('name')}
              className={`info-input ${formik.touched.name && formik.errors.name ? 'info-input-error' : ''}`}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="info-error">{formik.errors.name}</div>
            )}
          </div>

          <div className="info-form-group">
            <label className="info-label">{t('Current Address')}</label>
            <input
              type="text"
              {...formik.getFieldProps('address')}
              className={`info-input ${formik.touched.address && formik.errors.address ? 'info-input-error' : ''}`}
            />
            {formik.touched.address && formik.errors.address && (
              <div className="info-error">{formik.errors.address}</div>
            )}
          </div>

          <div className="info-form-group">
            <label className="info-label">{t('Email Address')}</label>
            <input
              type="email"
              {...formik.getFieldProps('email')}
              className={`info-input ${formik.touched.email && formik.errors.email ? 'info-input-error' : ''}`}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="info-error">{formik.errors.email}</div>
            )}
          </div>

          <div className="info-form-group">
            <label className="info-label">{t('(computer) password')}</label>
            <input
              type="password"
              {...formik.getFieldProps('password')}
              className={`info-input ${formik.touched.password && formik.errors.password ? 'info-input-error' : ''}`}
            />
            <button type="button" className="info-change-password">
              {t('Change Password')}
              <span className="info-change-label">{t('change')}</span>
            </button>
          </div>

          <div className="info-shared-users">
            <label className="info-label">{t('Shared users')}</label>
            <div className="info-users-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '1rem' }}>
              {sharedProfiles && sharedProfiles.map((user) => (
                <div key={user.id} className="info-user-item">
                  <div className={`info-user-avatar ${!user.profile_picture ? 'info-user-initial' : ''}`}>
                    {user.profile_picture ? (
                      <img src={user.profile_picture} alt={user.username} />
                    ) : (
                      <span>{user.initial}</span>
                    )}
                  </div>
                  <span className="info-username">{user.username}</span>
                </div>
              ))}
            </div>
          </div>

          <button type="submit" className="info-save-button">
            {t('Save Information')}
          </button>
        </form>
      </div>
    </>
  );
};

export default AccountInformation;
