// ZoomLink.js
import React, { useEffect, useState } from 'react';
import { MoveUpRight } from 'lucide-react';
import Navigation from './Navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getArtistClassVideoUrl } from '../redux/slices/contestSlice';
import { useTranslation } from 'react-i18next';

const Link = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { artistClassVideoUrl, isLoading } = useSelector(state => state.contest);
  const [isFree, setIsFree] = useState(false);
  const [classId, setClassId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const classIdFromParams = urlParams.get('class_id');
    if (classIdFromParams) {
      setClassId(classIdFromParams);
    } else if (location.state) {
      setClassId(location.state.class_id);
      setIsFree(location.state.is_free);
    }
  }, [location]);

  const handleSendVideoUrl = async () => {
    if (classId) {
      await dispatch(getArtistClassVideoUrl(classId));
    }
    if (artistClassVideoUrl) {
      console.log("artistClassVideoUrl:::::::::", artistClassVideoUrl);
      navigate(`/link-url`, { state: { artistClassVideoUrl: artistClassVideoUrl } });
    }
  };

  return (
    <>
      <Navigation />
      <div className="zoom-link-container">
        <div className="zoom-link-text">
          <h2>{t('To your registered email address')}</h2>
          <p>{t('The')} {isFree ? t('free') : t('paid')} {t('real-time Video URL will be sent to your registered email address.')}</p>
          <p>{t('Tap the link below.')}</p>
        </div>

        <button className="zoom-link-button" onClick={handleSendVideoUrl}>
          <MoveUpRight size={20} />
          <span>{t('Send')} {isFree ? t('free') : t('paid')} {t('real-time Video URL')}</span>
        </button>
      </div>
    </>
  );
};

export default Link;